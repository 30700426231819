import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routers = [
    // {
    //     path: '',
    //     redirect: 'index',
    // },
    // {
    //     path: '/index',
    //     component: () => import('@/views/index'),
    // },
    // {
    //     path: '/:project/:name/:index',
    //     component: () => import('@/views/index'),
    // },
    // {
    //     path: '/:project/:name/:index/*',
    //     component: () => import('@/views/index'),
    // },
    {
        path: '*',
        component: () => import('@/views/index'),
    }
]

const router = new VueRouter({
    mode: "history",
    routes: routers
});

export default router;
